<script>
// import Layout from "../../../../layouts/main";
// import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
 import { required ,maxLength,minLength,minValue,numeric} from "vuelidate/lib/validators";
// import { fetchSettings } from "../api";
// import infoStaff from './infoStaff.vue';
// import Infrastructure from './infrastructure.vue';
// import Multiselect from 'vue-multiselect'
  import Swal from "sweetalert2";
  import SideButtons from '../../../../../../components/side-buttons.vue';

export default {

    page: {
        
        meta: [{ name: "description", content: appConfig.description}],
    },
    // import : { fetchSettings },
    components: {SideButtons },
    props:['selectedComp','company'],
    data() {
      return {
      tableData: [],
      title: "Nouvelle entreprise",
      intra_entreprises_info: {
        intra_entreprises_name:'',
        intra_entreprises_phone:'',
        intra_entreprises_adr:'',
        comments:'',
        
      },
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      disabledAdd : false,
      disabledEdit : true,
      disabledDelete : true,
      disabled : true,
      disabledCancel : true,
      lastSelected : null,
      add : false,
      edit : false,
      destroy : false,
      send : false,
      have : false,
      resetForm:false
    };
  },
  validations: {
    intra_entreprises_info:{
      intra_entreprises_name: { required },
      intra_entreprises_phone: { required, numeric, maxLength:maxLength(14),minLength:minLength(9)},
      intra_entreprises_adr: { required },
      //comments: { required },
    }
  },
  watch:{
    add(){
      this.addBtn()
    },
    edit(){
      this.editBtn()
    },
    destroy(){
      this.deleteCompany()
    },

    selectedComp:{
      handler(val){
      if(val.length != 0 ){
        this.addSelectedRow(val)
      }else{
        this.disabled       = true;
        this.disabledAdd    = false;
        this.disabledDelete = true;
        this.disabledEdit   = true;
        this.disabledCancel = true;
        this.clearForm();
      }
    }  
    }
  },
  mounted(){

},

 methods:{
    addSelectedRow(val){
      this.disabled       = true;
      this.disabledAdd    = false;
      this.disabledDelete = false;
      this.disabledEdit   = false;
      this.disabledCancel = true; 
      this.lastSelected               = val    ;
      this.intra_entreprises_info.uuid          = val.uuid;
      this.intra_entreprises_info.intra_entreprises_name  = val.intra_entreprises_name;
      this.intra_entreprises_info.intra_entreprises_phone = val.intra_entreprises_phone;
      this.intra_entreprises_info.intra_entreprises_adr   = val.intra_entreprises_adr;
      this.intra_entreprises_info.comments      = val.comments;     
    },
    addBtn(){
      this.resetForm      = false
      this.disabled       = false;
      this.disabledAdd    = true;
      this.disabledEdit   = true;
      this.disabledCancel = false;
      this.clearForm();
    },
    editBtn(){
      this.resetForm      = false
      this.disabledAdd    = false;
      this.disabledEdit   = true;
      this.disabled       = false;
      this.disabledCancel = false;
    },
    cancelBtn(){
      this.resetForm      = true;
      this.disabled       = true;
      this.disabledAdd    = false;
      this.disabledEdit   = true;
      this.disabledCancel = true;
      if(this.lastSelected != null)
      this.addSelectedRow(this.lastSelected);
    },
    
    clearForm(){
      this.intra_entreprises_info.uuid          = "";
      this.intra_entreprises_info.intra_entreprises_name  = "";
      this.intra_entreprises_info.intra_entreprises_phone = "";
      this.intra_entreprises_info.intra_entreprises_adr   = "";
      this.intra_entreprises_info.comments      = "";
      this.resetForm = true;
        //this.Emp_info.Emp_mobile = this.selectedEmp.mobile;
        
    },

     formSubmit() {
       
      this.submitted = true;
      var valid = this.$v.intra_entreprises_info.$invalid;
      this.$v.$touch();
      // var validFormData = this._validateContractAmnts();

      if(!valid){
      this.submitted = false;

      this.$http
        .post("/referentiel/intra_entreprise/store", this.intra_entreprises_info)

        .then((res) => {
          var status = res.data.status;
          switch (status) {
            case 200:
              this.$toast.success(res.data.original.msg);
              // Swal.fire("Félicitations!", res.data.original.msg, "success");
              this.$emit('refrechTable', true);
              this.clearForm();
              this.cancelBtn()
              this.resetForm      = false
              break;

            case 500:
              Swal.fire("Avertissement!", res.data.original.msg, "warning");
              break;
          }
        })
        .catch((error) => {
          Swal.fire("Erreur!", error.message, "error");
        })
        .finally(() => {});
      }

    },
     deleteCompany() {
      var typeUid   = this.intra_entreprises_info.uuid
      var  typeName = this.intra_entreprises_info.intra_entreprises_name
      Swal.fire({
        title: "Êtes-vous sûr de supprimer l'Entreprise :" + typeName + "?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#f46a6a",
        cancelButtonColor: "#B8CCD7",
        cancelButtonText: "Annuler",
        confirmButtonText: "Oui!",
      }).then((result) => {
        if (result.value) {
          this.$http
            .post("/referentiel/intra_entreprise/delete/" + typeUid)
            .then((res) => {
              var status = res.data.original.status;
              switch (status) {
                case 200:
                  this.$toast.success(res.data.original.msg);
                  this.$emit('refrechTable', true);
                  this.clearForm();
                  this.lastSelected   = null;
                  this.resetForm      = false
                  break;

                case 500:
                  this.$toast.warning(res.data.original.msg);
                  break;
              }
            })
            .catch((error) => {
              this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    },
  }
    
}
</script>
<style>
  #StickyEF {
    position: fixed;
    right: 0;
    top: 50%;
    width: 8em;
    margin-top: -2.5em;
    z-index: 999;
    list-style: none;
  }
  .rounded-pillEF{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    border-radius: 50% !important;
  }
  .btn-addEF{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #044A72 ;
  }
  .btn-duplicateEF{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #097EA9 ;
  }
  .btn-editEF{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #FFB236 ;
  }
  .btn-deleteEF #dropdown-dropleft__BV_toggle_{
     border-radius: 50% !important;
  }
  .btn-deleteEF{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #D61337;
  }
  .btn-uploadEF{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #2A5769 ;
  }
  .btn-downloadEF{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #21BF92;
  }
  .btn-cancelEF{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #B8CCD7 ;
  }
  .form-check-input{
    font-size: 24px;
  }
</style>
<template>
    <!-- <Layout>
    <PageHeader :title="title" :items="items" /> -->
    <div class="row">
      <div class="col-12">
        <div class="card-body">
          <SideButtons
          :select="disabledAdd" :reset="resetForm"  :canEdit="disabledEdit" :save="send" :ODS="false"   @add="add = $event" @edit=" edit = $event"  @delete=" destroy = $event" />
          <ul id="StickyEF" class="d-none">
            <li>
              <button type="button" @click="addBtn" :disabled="disabledAdd" class="btn btn-addEF rounded-pillEF float-right mr-1 mt-1">
              <i class="fas fa-plus text-white"></i>
              </button>
            </li>
            <li>
              <button type="button" @click="editBtn" :disabled="disabledEdit" class="btn btn-editEF rounded-pillEF float-right mr-1 mt-1">
                <i class="fas fa-pencil-alt text-white" style=""></i>
              </button>
            </li>
            <li>
              <button type="button" :disabled="disabledDelete" @click="deleteCompany" class="btn btn-deleteEF rounded-pillEF float-right mr-1 mt-1">
                <i class="fas fa-trash-alt text-white" style=""></i>
              </button>
            </li>
          </ul>
          <h4 class="card-title">Details </h4>
          <fieldset :disabled="disabled">
            <form class="needs-validation" @submit.prevent="formSubmit" enctype="multipart/form-data">
            <div class="row">
              <div class="col-lg-4">
                <div class="form-group">
                  <label for="formrow-inputCity">Nom d'entreprise *</label>
                  <input type="text"  class="form-control" v-model="intra_entreprises_info.intra_entreprises_name" 
                    @blur="$v.intra_entreprises_info.intra_entreprises_name.$error"
                  :class="{
                    'is-invalid': submitted && $v.intra_entreprises_info.intra_entreprises_name.$error,
                  }" placeholder=""
                  />
                    <div
                      v-if="submitted && $v.intra_entreprises_info.intra_entreprises_name.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.intra_entreprises_info.intra_entreprises_name.required"
                        >Le champ est obligatoire.</span
                      >
                    </div>
                </div>
              </div>

              <div class="col-lg-4">
                
                <div class="form-group">
                  <label for="formrow-inputCity"
                    >Télèphone *</label
                  >
                  <input type="text"  class="form-control" v-model="intra_entreprises_info.intra_entreprises_phone" 
                    @blur="$v.intra_entreprises_info.intra_entreprises_phone.$error"
                  :class="{
                    'is-invalid': submitted && $v.intra_entreprises_info.intra_entreprises_phone.$error,
                  }" placeholder=""
                  />
                  <div
                      v-if="submitted && $v.intra_entreprises_info.intra_entreprises_phone.$error"
                      class="invalid-feedback"
                    >
                        <span v-if="!$v.intra_entreprises_info.intra_entreprises_phone.required">
                        Le champ est obligatoire.</span><br>
                      <span v-if="!$v.intra_entreprises_info.intra_entreprises_phone.numeric"
                      >le Numréo de Télèphone doit étre un nombre
                      .</span>
                      <br>
                      <span v-if="!$v.intra_entreprises_info.intra_entreprises_phone.minLength"
                      >La taille minimum du champs est: 9 nombres
                      .</span>
                      <br>
                      <span v-if="!$v.intra_entreprises_info.intra_entreprises_phone.maxLength"
                      >La taille maximum du champs est: 10 nombre
                      .</span>
                    </div>
                </div>
              </div>

              <div class="col-lg-4">
                <div class="form-group">
                  <label for="formrow-password-input">Adresse</label>
                  <input type="text"  class="form-control" v-model="intra_entreprises_info.intra_entreprises_adr" placeholder=""
                    @blur="$v.intra_entreprises_info.intra_entreprises_adr.$error"
                  :class="{
                    'is-invalid': submitted && $v.intra_entreprises_info.intra_entreprises_adr.$error,
                  }">
                  <div
                      v-if="submitted && $v.intra_entreprises_info.intra_entreprises_adr.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.intra_entreprises_info.intra_entreprises_adr.required"
                        >Le champ est obligatoire.</span
                      >

                    </div>
                </div>
              </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="formrow-inputCity">Commentaires *</label>
                    <textarea
                      v-model="intra_entreprises_info.comments"
                      class="form-control"
                      cols="30"
                      rows="1" 
                    ></textarea>
                  </div>
                </div>
            </div>
            <div class="row">
              <div class="col-md-12 " v-show="!disabled">
                <button type="button"  @click="cancelBtn" class="btn btn-light btn-label float-right ml-2">
                  <i class="fas fa-times-circle text-danger label-icon"></i> Annuler
                </button>
                <button type="submit" class="btn btn-label btn-primary float-right" >
                  <i class="far fa-save label-icon "></i> Enregistrer
                </button>
              </div>
            </div>
          </form>
          </fieldset>
        </div>
      </div>
    </div>
    <!-- </Layout> -->
</template>
